import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { orange, red, indigo } from '@material-ui/core/colors';
import { COLORS } from "../../utils/Constants";

const useStyles = makeStyles((theme) => ({
    
}));
const shadowStyle = '0 1px 9px 0 #21252934';
const OrangeButton = withStyles((theme) => ({
    root: {
        color: COLORS.orangeButtonText,
        fontWeight: 900,
        fontSize: 14,
        padding: `10px 10px`,
        width: "100%",
        boxShadow: shadowStyle,
        borderRadius: 7,
        backgroundColor: COLORS.orangeButtonBackground,
        textTransform: 'none',
        '&:disabled': {
            backgroundColor: '#ffe7ab',
            color: '#cebc8e'
        },
        '&:hover': {
            backgroundColor: COLORS.orangeButtonBackground,
        },
    },
}))(Button);
const BlueButton = withStyles((theme) => ({
    root :{
        color: COLORS.blueButtonText,
        fontWeight: 900,
        fontSize: 14,
        padding: `10px 10px`,
        width: "100%",
        borderRadius: 7,
        boxShadow: '0 1px 9px 0 #4d5bc4b8',
        backgroundColor: COLORS.blueButtonBackground,
        '&:hover': {
          backgroundColor: COLORS.blueButtonBackground,
        },
    }
}))(Button);
const RedButton = withStyles((theme) => ({
    root: {
        color: COLORS.redButtonText,
        fontWeight: 900,
        fontSize: 14,
        padding: `10px 10px`,
        width: "100%",
        borderRadius: 7,
        boxShadow: '0 1px 9px 0 #d35151b8',
        backgroundColor: COLORS.redButtonBackground,
        '&:hover': {
          backgroundColor: COLORS.redButtonBackground,
        },
    },
}))(Button);
const WhiteButton = withStyles((theme) => ({
    root: {
        color: COLORS.whiteButtonText,
        fontWeight: 900,
        fontSize: 14,
        padding: `10px 10px`,
        width: "100%",
        boxShadow: shadowStyle,
        backgroundColor: COLORS.whiteButtonBackground,
        borderColor: COLORS.whiteButtonBorder,
        borderWidth: 1,
        borderStyle: 'solid',
        '&:hover': {
          backgroundColor: COLORS.whiteButtonBackground,
        },
    },
}))(Button);
const BorderButton = withStyles((theme) => ({
    root: {
        color: COLORS.borderButtonText,
        fontWeight: 900,
        fontSize: 14,
        padding: `10px 10px`,
        width: "100%",
        boxShadow: shadowStyle,
        borderStyle: 'solid',
        borderColor: COLORS.borderButtonText,
        borderWidth: 2,
        backgroundColor: COLORS.borderButtonBackground,
        '&:hover': {
          backgroundColor: COLORS.borderButtonBackground,
        },
    },
}))(Button);
const GreyBorderButton = withStyles((theme) => ({
    root: {
        color: COLORS.whiteButtonText,
        fontWeight: 900,
        fontSize: 14,
        padding: `10px 10px`,
        width: "100%",
        boxShadow: shadowStyle,
        borderStyle: 'solid',
        borderRadius: 7,
        borderColor: COLORS.whiteButtonBorder,
        borderWidth: 1,
        backgroundColor: COLORS.whiteButtonBackground,
        '&:hover': {
          backgroundColor: COLORS.whiteButtonBackground,
        },
    },
}))(Button);

const RegisterButton = withStyles((theme) => ({
    root: {
        color: COLORS.whiteButtonText,
        fontWeight: 900,
        fontSize: 19,
        padding: `8px 10px`,
        width: "100%",
        borderStyle: 'solid',
        borderColor: '#DEDFE5',
        borderWidth: 2,
        backgroundColor: COLORS.whiteButtonBackground,
        '&:hover': {
          backgroundColor: COLORS.whiteButtonBackground,
        },
    },
}))(Button);
export default function FlatButton({ type, title, height, disabled, onClick, fontSize }) {
    const classes = useStyles();
    switch(type) {
        case 'orange':
            if (fontSize)
                return (
                    <OrangeButton style={{fontSize: fontSize}} disabled={disabled} onClick={onClick}>
                        {title}
                    </OrangeButton>
                );
            else
                return (
                    <OrangeButton style={height && {height: height}} disabled={disabled} onClick={onClick}>
                        {title}
                    </OrangeButton>
                );
            break;
        case 'blue':
            if (fontSize)
                return (
                    <BlueButton style={{fontSize: fontSize}} disabled={disabled} onClick={onClick}>
                        {title}
                    </BlueButton>
                );
            else
                return (
                    <BlueButton style={height && {height: height}} disabled={disabled} onClick={onClick}>
                        {title}
                    </BlueButton>
                );
            break;
        case 'red':
            if (fontSize)
                return (
                    <RedButton style={{fontSize: fontSize}} disabled={disabled} onClick={onClick}>
                        {title}
                    </RedButton>
                );
            else
                return (
                    <RedButton style={height && {height: height}} disabled={disabled} onClick={onClick}>
                        {title}
                    </RedButton>
                );
            break;
        case 'white':
            return (
                <WhiteButton onClick={onClick}>
                    {title}
                </WhiteButton>
            )
        case 'border':
            return (
                <BorderButton onClick={onClick}>
                    {title}
                </BorderButton>
            )
        case 'greyborder':
            if (fontSize)
                return (
                    <GreyBorderButton style={{fontSize: fontSize}} disabled={disabled} onClick={onClick}>
                        {title}
                    </GreyBorderButton>
                );
            else
                return (
                    <GreyBorderButton style={height && {height: height}} disabled={disabled} onClick={onClick}>
                        {title}
                    </GreyBorderButton>
                );
                break;
        case 'register':
            return (
                <RegisterButton onClick={onClick}>
                    {title}
                </RegisterButton>
            )
        default:
            return <div></div>;
    }
}