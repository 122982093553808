import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import FlatButton from '../FlatButton'
import { indigo, green, lightGreen } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { COLORS } from "../../utils/Constants";

const useStyles = makeStyles((theme) => ({    
    button: {
        color: 'black',
        fontWeight: 'bold',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E4E5EB',
        borderRadius: 7,
        fontSize: 16,
        margin: 5,
        textTransform: 'none',
        padding: '10px 15px',
        justifyContent: 'space-between',
    },
    icon: {
        color: COLORS.whiteButtonText
    },
    whiteicon: {
        color: COLORS.blueButtonText
    }
}));
export default function MenuButton({ title, disable=false, onClick, styles, revert }) {
    const classes = useStyles();
    return (
        <Button
            onClick={onClick}
            variant="outlined"
            className={classes.button}
            style = {styles}
            disabled={disable}
            endIcon={<ChevronRightIcon fontSize={'large'} className={revert ? classes.whiteicon : classes.icon}/>}
        >
            {title}
        </Button>
    );
}