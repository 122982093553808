import { call, put } from "redux-saga/effects";
import StudyAPI from "../../network/apis/StudyAPI";
import UserAPI from "../../network/apis/UserAPI";
import Auth from "../../utils/Auth";
import * as ACTIONS from "../actions/Mathbot";
import { dispatchSnackbarError } from "../../utils/Shared";
import { takeEvery,delay } from "redux-saga/effects";
import * as TYPES from "../types/Mathbot";
import { showSnackbarAction } from "../actions/snackbar";

export function* _login(action) {
  try {
    const data = yield call(UserAPI.login, action.payload.email, action.payload.password, action.payload.type);
    if (data.success == true) {
      Auth.setAuth(data.token);
      yield put(ACTIONS.loginSuccess(data.user))
      yield put(ACTIONS.getUserRequest());
    } else {
      if (data.reason == 'wrong_password')
        yield put(ACTIONS.loginFailed('Wrong password. Try again'))
      else
        yield put(ACTIONS.loginFailed('User not registered.'))
    }
  } catch (err) {
    dispatchSnackbarError({error: {message: "Failed to login"}});
  }
}

export function *_signup(action) {
  try {
    const data = yield call(UserAPI.register, action.payload);
    if (data.success == true) {
      Auth.setAuth(data.token);
      yield put(ACTIONS.registerSuccess())
      yield put(ACTIONS.getUserRequest());
    } else {
      if (data.reason == 'username_already_taken')
        dispatchSnackbarError({error: {message: "User already exist."}});
      else if (data.reason == 'email_already_taken')
        dispatchSnackbarError({error: {message: "Email already registered."}});
      else
        dispatchSnackbarError({error: {message: "Failed to register."}});
    }
  } catch (err) {
    dispatchSnackbarError({error: {message: "Failed to register."}});
  }
}

export function *_updateProfile(action) {
  try {
    const data = yield call(UserAPI.updateProfile, action.payload);
    if (data.success == true) {
      Auth.setAuth(data.token);
      yield put(ACTIONS.updateProfileSuccess())
      yield put(ACTIONS.getUserRequest());
    } else {
      dispatchSnackbarError({error: {message: "Failed to update account."}});
    }
  } catch (err) {
    dispatchSnackbarError({error: {message: "Failed to update account."}});
  }
}

export function *_updatePassword(action) {
  try {
    const data = yield call(UserAPI.updatePassword, action.payload.password, action.payload.newpassword, "update");
    if (data.success == true) {
      Auth.setAuth(data.token);
      yield put(ACTIONS.updatePasswordSuccess())
      yield put(ACTIONS.getUserRequest());
      yield put(showSnackbarAction("Update password success", "success"));
    } else {
      if (data.reason == 'wrong_password')
        dispatchSnackbarError({error: {message: "Please input correct password."}});
      else
        dispatchSnackbarError({error: {message: "Failed to update password."}});
    }
  } catch (err) {
    dispatchSnackbarError({error: {message: "Failed to update password."}});
  }
}

export function *_resetPassword(action) {
  try {
    const data = yield call(UserAPI.updatePassword, action.payload.token, action.payload.newpassword, "reset", action.payload.email);
    if (data.success == true) {
      Auth.setAuth(data.token);
      yield put(ACTIONS.resetPasswordSuccess())
      yield put(showSnackbarAction("Reset password success, Please go to login page", "success"));
    } else {
        dispatchSnackbarError({error: {message: "Failed to reset password."}});
    }
  } catch (err) {
    dispatchSnackbarError({error: {message: "Failed to reset password."}});
  }
}

export function* _getBotList() {
  try {
    const response = yield call(StudyAPI.getBotList);
    yield put(ACTIONS.getBotReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* _getUser(action) {
  try {
    console.log("Auth", Auth.isAuth())
    if (Auth.isAuth()){
      const response = yield call(UserAPI.getUserProfile)
      yield put(ACTIONS.getUserReceive(response.data));
    } else {
      //dispatchSnackbarError({error: {message: "Failed to fetch"}});
    }
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}

export function* _getProgress() {
  try {
    const response = yield call(UserAPI.getUserProgress)
    yield put(ACTIONS.getProgressReceive(response.data));
  } catch (err) {
    dispatchSnackbarError(err.response.data);
  }
}



export function* allSagas() {
  yield takeEvery(TYPES.LOGIN_REQUEST, _login);
  yield takeEvery(TYPES.SIGNUP_REQUEST, _signup);
  yield takeEvery(TYPES.UPDATE_PROFILE_REQUEST, _updateProfile);
  yield takeEvery(TYPES.UPDATE_PASSWORD_REQUEST, _updatePassword);
  yield takeEvery(TYPES.GET_BOT_REQUEST, _getBotList);
  yield takeEvery(TYPES.GET_USER_REQUEST, _getUser);
  yield takeEvery(TYPES.GET_PROGRESS_REQUEST, _getProgress);
  yield takeEvery(TYPES.RESET_PASSWORD_REQUEST, _resetPassword);
}
