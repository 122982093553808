import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import FlatButton from '../FlatButton'

const useStyles = makeStyles((theme) => ({
    root: {
    },
    button: {
        minHeight: 75,
        minWidth: 300,
        width: "100%",
        fontSize: 22,
        textAlign: 'left'
    },
    focusVisible: {

    },
    image: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        borderRadius: 12,
        backgroundSize: 'cover',
        backgroundPosition: 'center 50%',
        boxShadow: '0 1px 9px 0 #21252934'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    toprow: {
        display: 'flex',
        flexDirection: 'row',        
        alignItems: 'center',
    },
    bottomrow: {
        display: 'flex',
        flexDirection: 'row',        
        alignItems: 'center',
        justifyContent: 'center'
    },
    left: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
    },
    subtitle: {
        position: 'relative',
        color: 'white',
        fontWeight: '400',
        fontSize: 15,
        padding: `0 16px`,
        marginBottom: 5
    },
    heading: {
        position: 'relative',
        color: 'white',
        fontWeight: 'bold',
        paddingLeft: 30,        
        whiteSpace: 'nowrap',
        width: '50%',
        fontFamily: 'LotaGrotesqueAlt3',
        fontSize: 22,
        minWidth: 200,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    right: {
        display: 'flex',
        flexDirection: 'column', 
        position: 'absolute',
        right: 20,
        alignItems: 'center'
    },
    badge: {
        zIndex: 10,
        fontSize: 12,
        fontWeight: '700',
        background: 'white',
        borderRadius: 12,
        padding: `5px 14px`,
        width: 'fit-content'       
    },
    date: {
        color: 'white',
        fontSize: 12,
        fontWeight: '700',
        borderRadius: 10,
        padding: `3px 5px`,
        float: 'right'
    },
    buttonWrapper: {
        padding: '10px 20px',
        width: '100%'
    },
    crown: {
        zIndex:0,
        position: "absolute",
        top: -16,
        right: -16
    }
}));
const topicimages = {
    'G': require('../../assets/images/geoButton.png'),
    'OA': require('../../assets/images/arithButton.png'),
    'NBT': require('../../assets/images/baseButton.png'),
    'MD': require('../../assets/images/measureButton.png'),
    'NF': require('../../assets/images/fractionButton.png'),
};
const gradeimages = {
    3: require('../../assets/images/g3Button.png'),
    4: require('../../assets/images/g4Button.png'),
    5: require('../../assets/images/g5Button.png'),
    6: require('../../assets/images/g6Button.png'),
    7: require('../../assets/images/g7Button.png'),
};
export default function LectureButton({ 
    topic = '',
    grade = 0,
    subtitle,
    title,
    date,
    progress,
    actionButtonTitle,
    style,
    onClick,
    titleFontSize=24
}) {
    const classes = useStyles();
    console.log(topic, grade, "######")
    return (
        <div className={classes.root}>
            <ButtonBase
                focusRipple
                className={classes.button}
                style={style}
                focusVisibleClassName={classes.focusVisible}
                onClick={onClick}
            >
                <span
                    className={classes.image}
                    style={{
                        backgroundImage: `url(${topic ? topicimages[topic] : gradeimages[grade]})`,
                    }}
                />
                <div className={classes.content}>
                    <div className={classes.toprow}>
                        <div className={classes.left}>
                            {subtitle && <div
                                className={classes.subtitle}
                            >
                                {subtitle}                                
                                {date && <span className={classes.date}>
                                    {date}
                                </span>}
                            </div>}
                            {title && <span
                                className={classes.heading}
                                style={{fontSize: titleFontSize}}
                            >
                                {title}
                            </span>}
                        </div>
                        <div className={classes.right}>
                            {progress == 100 && 
                            <img className={classes.crown} src={require("../../assets/images/crown.png")}/>}
                            {progress != undefined && <span className={classes.badge}>
                                {progress}%
                            </span>}
                        </div>
                    </div>
                    {actionButtonTitle && <div className={classes.bottomrow}>
                        <div className={classes.buttonWrapper}>
                            <FlatButton type="orange" title={actionButtonTitle}/>
                        </div>
                    </div>}
                </div>
            </ButtonBase>
        </div>
    );
}