
import { BASE_URL } from "./Constants";
import React from 'react';
import Sound from 'react-sound';
import schema from './chatbot/schemas/schema';
import ImageZoom from 'react-medium-image-zoom'

export const _GRADE = (code) => {
    return code.split('.')[0]
}
export const _TOPIC = (code) => {
    return code.split('.')[1]
}
export const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
}
export const formatHour = (hour) => {
    if (hour > 3600)
        hour = Number( hour / 3600 ).toFixed(1) + " hours";
    else if (hour > 60)
        hour = Number( hour / 60 ).toFixed(0) + " mins";
    else
        hour = hour + " secs";
    return hour;
}

export const formatScore = (point) => {    
    if (point > 1000)
        point = Number( point / 1000 ).toFixed(1) + "K";
    return point;
}

export const shuffle = (array) => {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}
export const getImageAbsURL = (url) => {
  var res = url;
  if (url.startsWith("avatar/"))
      res = BASE_URL + "/media/" + url;
  
  return res;
}
export const validateEmail = (mail) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail))
  {
    return (true)
  }
  return (false)
}


const findFileURL = (fileList, fileName, type) => {
    let value = '';
    fileList.map(file => {
      if (file.fileName === fileName && file.mediaType === type) {
        value = file;
      }
    });
    return value;
  }

const getIndex = (data, stepNo) => {
    let count = 0;
    for (let index = 0; index < data.length; index++) {
        if (stepNo === data[index].Step) {
        break;
        }
        count++;
    }
    return count;
}
export const makeSteps = (data, fileList, LSData, userName, customTrigger, saveUserScore) => {
    let steps = [];
    let progress = [];
    let currentProgress = 1;
    let inputPattern = '';
    let inputStep = -2;
    let progressTrigger = 0;
    let flag = false;
    let endId = 0;
  
    for (let index = 0; index < data.length; index++) {
      const each = data[index];
      
      if (!flag) {
        if (LSData.Progress != 0 && LSData.Progress == parseInt(each.Progress)) {
          flag = true;
          console.log('checking progressTrigerr', index, each, each.Goto)
          progressTrigger = each.Goto ? each.Goto : index + 1;
        }
      }
  
      let row = {};
      row.id = index.toString();
      if (/input/gi.test(each.Command)) {
        // input
        if (each.Type === 'text') {
          // real input, input some texts
          row.user = true;
          inputPattern = each.Content;
          inputStep = index;
          row.validator = inputValue => {
            // validation
            if (each.Max !== '') {
              //  length of text
              if (inputValue.length > each.Max) {
                return 'The max length is ' + each.Max;
              }
            }
            if (each.Rule !== '') {
              //text rule like A~Za~z
              const regEx = new RegExp('^' + each.Rule + '+$');
              if (!regEx.test(inputValue)) {
                return 'Please input valid text : ' + each.Rule;
              }
            }
            return true;
          };
          if (
            // input <answer> next and next next is if and else
            each.Content === '<answer>' &&
            /if/gi.test(data[index + 1].Command) &&
            /else/gi.test(data[index + 2].Command)
          ) {
            const firstGoto = getIndex(data, data[index + 1].Goto);
            const secondGoto = getIndex(data, data[index + 2].Goto);
            const dataContent = data[index + 1].Content;
            row.trigger = ({value, steps, metadata}) => {
              console.log(value, dataContent, firstGoto, secondGoto);
              return value === dataContent ? firstGoto : secondGoto;
            };
            index += 2;
          } else {
            row.trigger = index + 1;
          }
        } else if (each.Type == 'nextbutton') {
          row.options = [
            {value: 1, label: each.Content, trigger: index + 1},
          ];
        } else if (each.Type == 'button') {
          row.options = [
            {value: 1, label: each.Content, trigger: index + 1},
          ];
        }
        // console.log(row);
      } else if (/print/gi.test(each.Command)) {
        // print text
        if (index === data.length - 1) {
          row.end = true;
          endId = each.Step;
        } else {
          row.trigger = index + 1;
        }
        if (each.Type == 'image') {
          // show image
          const file = findFileURL(fileList, each.Content, 'image');
          // console.log('imageUrl', file.file);
          if (each.Content === 'explosion.gif') {
            row.component = (
              <div style={{width: 405, height: 0}}>
              </div>
            );
            row.metadata = { type: 'explosion'};
            row.trigger = customTrigger
            //row.customDelay = 0;
            //row.delay = 0;
          } else if (file.file !== undefined) {
            // image url exist
            row.component = (
              <div
                style={{
                  borderStyle: 'solid',
                  borderColor: '#3F60FF',
                  borderWidth: 10,
                  borderRadius: 10,
                  borderBottomLeftRadius: 0,
                }}>
                <ImageZoom
                  image={{
                    src: encodeURI(BASE_URL + file.file),
                    className: 'img',
                    style: {
                      width: 200,
                      height: (200 / file.width) * file.height,
                      padding: 10,
                    }
                  }}
                  zoomImage={{
                    src: encodeURI(BASE_URL + file.file),
                  }}
                />
              </div>
            );
            row.metadata = { uri: encodeURI(BASE_URL + file.file)};
          } else {
            // image url not exist
            // console.log('here', each.Content);
            row.message = each.Content;
          }
        } else if (each.Type == 'sound') {
          // play sound
          const file = findFileURL(fileList, each.Content, 'sound');
          row.component = (          
            <div></div>
          );
          if (!file)
            row.metadata = { type: 'skip'};  
          else
            row.metadata = { type: 'sound', uri: encodeURI(BASE_URL + file.file)};
          row.trigger = customTrigger
          //row.customDelay = 3000;
          //row.delay = 0;
        } else {
          // show text
          row.message = each.Content.replace('<name>', userName);
        }
        if (inputStep === index - 1) {
          // show text if previous is input
          const tempMessage = each.Content.split(inputPattern);
          row.message = ({previousValue}) =>
            tempMessage[0] + previousValue + tempMessage[1];
          // console.log(row.message);
        }
      } else if (/if/gi.test(each.Command)) {
        let options = [];
        let value = 1;
        while (/if/gi.test(data[index].Command)) {
          if (data[index].Type == 'clickable link') {
            // go next
            options.push({
              value: value,
              label: data[index].Content,
              trigger: data[index].Content,
            });
          } else if (data[index].Type == 'imagebutton') {
            //button with image
            const file = findFileURL(
              fileList,
              data[index].Content,
              'image',
            );
            if (file.file !== undefined) {
              const width = 200;
              const height = (200 / file.width) * file.height;
              options.push({
                value: value,
                label: data[index].Content,
                trigger: getIndex(data, data[index].Goto),
                type: 'image',
                imageUrl:  BASE_URL + file.file,
                width: width,
                height: height,
              });
            }
          } else {
            // go to "goto" step
            if (data[index].Goto == 'continue') {
              options.push({
                value: value,
                label: data[index].Content,
                trigger: getIndex(data, data[index + 1].Step),
              });
            } else {
              options.push({
                value: value,
                label: data[index].Content,
                trigger: getIndex(data, data[index].Goto),
              });
            }
          }
          index++;
          value++;
        }
        index--;
        row.options = options;
      } else if (each.Command == 'Play') {
        // play sound
        if (index === data.length - 1) {
          row.end = true;
          endId = each.Step;
        } else {
          row.trigger = index + 1;
        }
        if (each.Type == 'sound') {
          const file = findFileURL(fileList, each.Content, 'sound');
          row.component = (
              <div></div>
          );          
          if (!file)
            row.metadata = { type: 'skip'};  
          else
            row.metadata = { type: 'sound', uri: encodeURI(BASE_URL + file.file)};
          row.trigger = customTrigger
        }
      }
      if (each.Progress) {
        // console.log('progress', each.Progress, each, row)
        if (currentProgress === parseInt(each.Progress, 10)) {
          progress.push(row.id);
          currentProgress = currentProgress + 1;
          let prevTrigger = row.trigger;
          row.trigger = ({value, steps}) => {
            saveUserScore(parseInt(each.Progress, 10), LSData);
            return prevTrigger;
          }
        }
      }
      //console.log(row);
      steps.push(row);
    }
    console.log(steps.length);
    console.log(progress);
    try {
      for (let i = 0, len = steps.length; i < len; i += 1) {
        const step = steps[i];
        schema.parse(step);
      }
      //schema.checkInvalidIds(steps);
    } catch (e) {
      if (e) {
        console.log('asdfasdf', e);
        //alert(e.toString());
       // return;
      }
    }
    console.log('before navigate chatbotscreen', steps)
    return {
      steps,
      progress,
      progressTrigger,
      endId
    };
}

export default {
}