import React, { Fragment, useEffect, useState } from "react";
import Navbar from "../components/Navbar/Navbar";
import { Router } from "react-router-dom";
import history from "../routes/History";
import Routes from "../routes/Routes";
import { IntlProvider } from "react-intl";
import messages from "../assets/Local/messages";
import { MaterialSnackbar } from "../components/Snackbar/Snackbar";
import Loader from "../components/Loader/Loader";
import "./App.scss";
import { connect, useDispatch } from "react-redux";
import { getUserRequest, getProgressRequest } from "../store/actions/Mathbot";

const App = function(props) {
  const [isNav, setNav] = useState(false);
  const dispatch = useDispatch();
  const { lang , loading } = props;

  useEffect(() => {
    const path = history.location.pathname;
    setNavState(path);  
    history.listen(onRouteChanged);
    if (!props.isGuest)
      dispatch(getUserRequest());
  }, []);

  const onRouteChanged = (value) => {
    const path = value.pathname;
    setNavState(path);
  }

  const setNavState = (path) => {
    if (path.startsWith('/learn') || path.startsWith('/practice')
    || path.startsWith('/account') || path.startsWith('/password') || path.startsWith('/howto'))
      setNav(true);
    else
      setNav(false);
  }
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <div
        className={lang === "ar" ? "rtl" : "ltr"}
        dir={lang === "ar" ? "rtl" : "ltr"}
      >
        {loading ? <Loader /> : null}
        <Router history={history}>
          <MaterialSnackbar />
          {isNav && <Navbar />}
          {Routes}
        </Router>
      </div>
    </IntlProvider>
  );
}

const mapStateToProps = ({ lang, loading, mathbot }) => ({
  lang,
  loading,
  isGuest: mathbot.isGuest
});

const mapActions = () => ({
})
export default connect(mapStateToProps, mapActions)(App);
