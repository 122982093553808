import React, { Suspense } from "react";
import { Router, Switch } from "react-router-dom";
import history from "./History";
import * as LazyComponent from "../utils/LazyLoaded";
import Loader from "../components/Loader/Loader";
import PrivateRoute from "../utils/PrivateRoute";

const Routes = (
  <Suspense fallback={<Loader />}>
    <Router history={history}>
      <Switch>
        {/* For private routes */}
        <PrivateRoute component={LazyComponent.LearnPage} path="/learn" exact />
        <PrivateRoute component={LazyComponent.Practice} path="/practice" exact />
        <PrivateRoute component={LazyComponent.Lesson} path="/lesson/:id" exact />
        <PrivateRoute component={LazyComponent.LessonEnd} path="/lesson-end" exact />
        <PrivateRoute component={LazyComponent.TestBegin} path="/test-begin" exact />
        <PrivateRoute component={LazyComponent.Test} path="/test" exact />
        <PrivateRoute  component={LazyComponent.Account} path="/account" exact />
        <PrivateRoute  component={LazyComponent.Password} path="/password" exact />
        <PrivateRoute  component={LazyComponent.HowTo} path="/howto" exact />
        {/* Public routes that doesn't need any auth */}
        <LazyComponent.Home path="/" exact />
        <LazyComponent.HomeTopic path="/home-topic" exact />
        <LazyComponent.Login path="/login" exact />
        <LazyComponent.Register path="/register" exact />
        <LazyComponent.ResetPassword path="/change-password"/>
        <LazyComponent.NotFound path="**" title="This page doesn't exist..." exact />
      </Switch>
    </Router>
  </Suspense>
);

export default Routes;
