import React from "react";

export const Home = React.lazy(() => import('../containers/Home/Home'));
export const HomeTopic = React.lazy(() => import('../containers/HomeTopic/HomeTopic'))
export const LearnPage = React.lazy(() => import('../containers/Learn/Learn'))
export const Practice = React.lazy(() => import('../containers/Practice/Practice'))
export const Login = React.lazy(() => import('../containers/Login/Login'));
export const Register = React.lazy(() => import('../containers/Register/Register'));
export const Lesson = React.lazy(() => import('../containers/Lesson/Lesson'));
export const LessonEnd = React.lazy(() => import('../containers/Lesson/End'));
export const TestBegin = React.lazy(() => import('../containers/Test/Begin'));
export const Test = React.lazy(() => import('../containers/Test/Test'));
export const Account = React.lazy(() => import('../containers/Settings/Account'));
export const Password = React.lazy(() => import('../containers/Settings/Password'));
export const ResetPassword = React.lazy(() => import('../containers/Auth/ResetPassword'));
export const HowTo = React.lazy(() => import('../containers/Settings/HowTo'));
export const NotFound = React.lazy(() => import('../components/NotFound/NotFound'));
