import Auth from "../../utils/Auth";
import { GUESTTOKEN } from "../../utils/Constants";
import * as types from "../types/Mathbot";

const INITIAL_STATE = {
  loginMSG: '',
  registerMSG: '',
  isGuest: Auth.isAuth() == GUESTTOKEN,
  user: null,
  progress: null,
};

// Replace with you own reducer
export default (state = INITIAL_STATE, action) => {
  console.log(action)
  switch (action.type) {
    case types.LOGIN_GUEST:
      return {
        ...state,
        isGuest: true
      }
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        isGuest: false,
        loginMSG: '',
      }
    case types.LOGIN_FAILED:
      return {
        ...state,
        loginMSG: action.payload
      }
    case types.LOGOUT:
      return {
        ...state,
        user: null,
        isGuest: null,
        progress: null,
        loginMSG: '',
        registerMSG: ''
      }
    case types.SIGNUP_SUCCESS:
      return {
        ...state,
        isGuest: false,
        registerMSG: ''
      }
    case types.SIGNUP_FAILED:
      return {
        ...state,
        registerMSG: action.payload
      }
    case types.UPDATE_PROFILE_SUCCESS:
      return {
        ...state
      }
    case types.UPDATE_PROFILE_FAILED:
      return {
        ...state
      }
    case types.GET_USER_RECEIVE:
      return {
        ...state,
        user: action.payload
      };
    case types.GET_PROGRESS_RECEIVE:
      return {
        ...state,
        progress: action.payload
      }
    default:
      return state;
  }
};
