import React, {useState} from 'react';
import { makeStyles,withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import FlatButton from '../FlatButton'
import { indigo, green, lightGreen } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import LectureButton from '../LectureButton';
import MenuButton from './menubutton';
import SwitchButton from './switchbutton';
import { useHistory } from "react-router-dom";
import Auth from '../../utils/Auth';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions/Mathbot';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import TextField from '@material-ui/core/TextField';
import StudyAPI from '../../network/apis/StudyAPI';

export default function ProfileMenu({ isGuest, profile, handleClose }) {
    const classes = useStyles();
    let history = useHistory();
    let dispatch = useDispatch();
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [feedback, setFeedback] = useState("");
    const [bContact, setContactModal] = useState(false);


    const logout = () => {
        Auth.signOut();
        dispatch(logoutUser())
        history.push('/login');
    }

    const goToAccount = () => {
        history.push('/account');
        handleClose();

    }
    const goToPassword = () => {
        history.push('/password');
        handleClose();

    }
    const switchNotification = () => {

    }
    const gotoSubscription = () => {
        handleClose();

    }
    const gotoHowTo = () => {
        history.push('/howto');
        handleClose();

    }
    const gotoContact = () => {
        setContactModal(true);
    }
    const gotoTerms = () => {
        window.open('https://www.mind-matters.co/privacy-policy-edu');
        handleClose();

    }
    const gotoFeedback = () => {
        setFeedbackModal(true)
        //handleClose();

    }
    const gotoFind = () => {
        window.open('https://www.tutoringboston.com/');
        handleClose();

    }
    const handleModalClose = () => {
        setFeedbackModal(false)
    }
    const submitFeedback = () => {           
        const email = isGuest ? 'Guest' : profile.email;
        const name = isGuest ? 'Guest' : profile.name;
        StudyAPI.setRating('', email, name, rating, feedback)
            .then(resp => {
                setFeedbackModal(false);
            })
    }
    const handleContactClose = () => {
        setContactModal(false);
    }

    return (
        <div className={classes.root}>
            <span className={classes.arrow2}></span>
            <span className={classes.arrow1}></span>
            <Card className={classes.card}>
                <CardContent style={{padding: 5}}>                    
                    <div className={classes.heading}>Settings</div>
                    <div className={classes.subheading}>Profile</div>
                    <div className={classes.buttonstack}>
                        {!isGuest && <MenuButton title={"Account details"} onClick={goToAccount}/>}
                        {!isGuest && <MenuButton title={"Change password"} onClick={goToPassword}/>}
                        {!isGuest && <MenuButton title={"Subscription settings"} disable={true} onClick={gotoSubscription}/>}
                        <MenuButton title={isGuest ? " Exit to Login" : "Logout"} onClick={logout}/>
                    </div>
                    <div className={classes.subheading}>App Settings</div>
                    <div className={classes.buttonstack}>
                        <SwitchButton title={"Notifications"} disable={isGuest} onChange={switchNotification}/>
                        <MenuButton title={"How to use & app info"} onClick={gotoHowTo}/>
                        <MenuButton title={"Contact us"} onClick={gotoContact}/>
                    </div>
                    <div className={classes.subheading}>Other</div>
                    <div className={classes.buttonstack}>
                        <MenuButton title={"Terms & privacy"} onClick={gotoTerms}/>
                        <MenuButton revert={true} styles={{color: 'white', backgroundColor: '#566AFF'}} title={"Send feedback"} onClick={gotoFeedback}/>
                        <MenuButton revert={true} styles={{color: 'white', backgroundColor: '#566AFF'}} title={"Find a tutor"} onClick={gotoFind}/>
                    </div>
                </CardContent>
            </Card>                        
            <Dialog onClose={handleContactClose} aria-labelledby="customized-dialog-title" open={bContact}>
                <DialogTitle classes={classes} id="customized-dialog-title" onClose={() => setContactModal(false)}>
                Contact us
                </DialogTitle>
                <DialogContent classes={classes} dividers>
                    <div className={classes.contactText}>You can email us at baris@mind-matters.co for any questions, suggestions or feedback</div>                    
                </DialogContent>
            </Dialog>                        
            <Dialog onClose={handleModalClose} aria-labelledby="customized-dialog-title" open={feedbackModal}>
                <DialogTitle classes={classes} id="customized-dialog-title" onClose={() => setFeedbackModal(false)}>
                Send Feedback
                </DialogTitle>
                <DialogContent classes={classes} dividers>
                    <div className={classes.feedbackText}>Rate the app</div>                                
                    <ToggleButtonGroup size="medium" value={rating} exclusive onChange={(e, v) => setRating(v)} >
                        <ToggleButton value="1" className={classes.togleButton}>
                            1
                        </ToggleButton>
                        <ToggleButton value="2" className={classes.togleButton}>
                            2
                        </ToggleButton>
                        <ToggleButton value="3" className={classes.togleButton}>
                            3
                        </ToggleButton>
                        <ToggleButton value="4" className={classes.togleButton}>
                            4
                        </ToggleButton>
                        <ToggleButton value="5" className={classes.togleButton}>
                            5
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <div className={classes.feedbackText}>Thank you! Any feedback on how we could improve the lesson?</div>                                
                    <TextField
                        multiline
                        rows={4}
                        fullWidth
                        placeholder="Enter your feedback"
                        variant="outlined"
                        className={classes.feedback}
                        onChange={(v) => {setFeedback(v.target.value)}}
                    />
                    <FlatButton type="orange" title={"Send feedback"} onClick={submitFeedback}/>
                </DialogContent>
            </Dialog>
        </div>
    );
}


const useStyles = makeStyles((theme) => ({    
    root: {
        position: 'relative',
        display: 'grid'
    },
    arrow1 : {
        content: " ",
        position: 'absolute',
        top: 0,
        right: 18,
        borderStyle: 'solid',
        borderWidth: '8px 13px 8px 0',
        transform: 'rotate(90deg)',
        borderColor: 'transparent white transparent transparent'
    },
    arrow2 : {
        content: " ",
        position: 'absolute',
        top: -2,
        right: 18,
        borderStyle: 'solid',
        borderWidth: '8px 13px 8px 0',
        transform: 'rotate(90deg)',
        borderColor: 'transparent #E4E5EB transparent transparent'
    },
    card: {
        marginTop: 10,
        padding: 12,
        boxShadow: 'none',
        borderColor: '#E4E5EB',
        borderStyle: 'solid',
        borderWidth: 2,
        borderRadius: 8,
        backgroundColor: 'white'
    },
    switch: {
        width: "100%",
    },
    switchButton: {
        width: "100%"
    },
    status: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20
    },
    statusLabel: {
        backgroundColor: indigo[500],
        color: 'white',
        padding: `3px 10px`,
        borderRadius: 10,
        fontSize: 12,
        fontWeight: 500
    },
    avatarWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    title: {
        marginTop: 10,
        fontWeight: 700,
        fontSize: 14,
        color: '#000'
    },
    done: {
        backgroundColor: green[600],
        color: 'white',
        borderRadius: '50%'
    },
    buttonstack: {
        display: 'flex',
        flexDirection: 'column',        
        '&>button': {
            marginTop: 5,
        }
    },
    lecturelist: {
        '&>div': {
            marginTop: 20,
            '&>button': {                
                minWidth: 100
            }
        }
    },
    heading: {
        fontWeight: 'bold',
        fontSize: 24,
        color: '#2B313F',
        marginBottom: 10,
    },
    subheading: {
        fontWeight: 'bold',
        fontSize: 20,
        padding: '15px 0px',
        color: '#2B313F'
    },
    /* feedback modal */    
    desc: {
        fontWeight: 600,
        color: 'white',
        fontSize: 22,
    },
    feedbackText: {
        fontWeight: 600,
        fontSize: 22,
        color: "black",
        padding: "10px 0px"
    },
    contactText: {
        fontSize: 18,
        color: "black",
        padding: "10px 0px"
    },
    feedback: {
        marginBottom: 10
    },
    togleButton: {
        width: 50,
        height: 60,
        fontWeight: 800,
        '&.Mui-selected': {
            backgroundColor: '#475ae9',
            color: 'white'
        },
        '&:hover': {
            backgroundColor: '#7c88e4 !important',
            color: 'white'
        }
    },
}));

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    ruleHeading: {
        fontWeight: 700,
    },
    ruleText: {
        fontWeight: 700,
        fontFamily: 'LotaGrotesqueAlt3'
    },
    closeicon: {
    },
    flag: {
        marginLeft: 20,
        width: 30,
        height:30
    }
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography className={classes.ruleHeading} variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  
const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      '& p': {
          fontFamily: 'LotaGrotesqueAlt3',
      }
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);