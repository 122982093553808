import axios from "axios";
import { requestHandler, successHandler, errorHandler } from "../interceptors";
import { BASE_URL } from "../../utils/Constants";
import Auth from "../../utils/Auth";

//'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjE1OTc3MTk4LCJqdGkiOiIyYTY4YzdiODBhYWU0MzhjOWM5ZWU1ZTdlNzVkOTNhNCIsInVzZXJfaWQiOjd9.C0paFY2ilhs4MOT_AD9IAq85jwsNA3NqCXzw7muMASQ'
 //Auth.isAuth();
//add your BASE_URL to Constants file
export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${Auth.isAuth()}`
  }
});

// Handle request process
axiosInstance.interceptors.request.use(request => requestHandler(request));
// Handle response process
axiosInstance.interceptors.response.use(
  response => successHandler(response),
  error => errorHandler(error)
);
