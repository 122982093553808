import * as types from "../types/Mathbot";


export const loginRequest = (email, password, type) => ({
  type: types.LOGIN_REQUEST,
  payload: {email, password, type}
})
export const loginSuccess = (payload) => ({
  type: types.LOGIN_SUCCESS,
  payload: payload
})
export const loginFailed = (msg) => ({
  type: types.LOGIN_FAILED,
  payload: msg
})
export const loginGuest = () => ({
  type: types.LOGIN_GUEST
})
export const logoutUser = () => ({
  type: types.LOGOUT
})
export const registerRequest = (form) => ({
  type: types.SIGNUP_REQUEST,
  payload: form
})
export const registerSuccess = () => ({
  type: types.SIGNUP_SUCCESS,
})
export const registerFailed = (msg) => ({
  type: types.SIGNUP_FAILED,
  payload: msg
})
export const getBotRequest = () => ({
  type: types.GET_BOT_REQUEST
});

export const getBotReceive = payload => ({
  type: types.GET_BOT_RECEIVE,
  payload
});

export const getUserRequest = () => ({
  type: types.GET_USER_REQUEST
})

export const getUserReceive = (payload) => ({
  type: types.GET_USER_RECEIVE,
  payload
})

export const getProgressRequest = () => ({
  type: types.GET_PROGRESS_REQUEST
})

export const getProgressReceive = (payload) => ({
  type: types.GET_PROGRESS_RECEIVE,
  payload
})

export const updateProfileRequest = (payload) => ({
  type: types.UPDATE_PROFILE_REQUEST,
  payload: payload
})

export const updateProfileSuccess = () => ({
  type: types.UPDATE_PROFILE_SUCCESS
})

export const updateProfileFailed = () => ({
  type: types.UPDATE_PROFILE_FAILED
})

export const updatePasswordRequest = (password, newpassword) => ({
  type: types.UPDATE_PASSWORD_REQUEST,
  payload: {
    password, 
    newpassword
  }
})

export const updatePasswordSuccess = () => ({
  type: types.UPDATE_PASSWORD_SUCCESS
})

export const updatePasswordFailed = () => ({
  type: types.UPDATE_PASSWORD_FAILED
})

export const resetPasswordRequest = (email, token, newpassword) => ({
  type: types.RESET_PASSWORD_REQUEST,
  payload: {
    email: email,
    token: token,
    newpassword
  }
})

export const resetPasswordSuccess = () => ({
  type: types.RESET_PASSWORD_SUCCESS
})

export const resetPasswordFailed = () => ({
  type: types.RESET_PASSWORD_FAILED
})