import { axiosInstance } from "./index";
import Auth from "../../utils/Auth";
const handlerEnabled = false;

// Replace endpoint and change api name
const login = async (email, password, type) => {
  const formData = new FormData();
  if (type == 'google' || type == 'facebook') {
    formData.append('email', email.email)
    formData.append('name', email.name)
    formData.append('avatar', email.avatar)
    formData.append('type', type)
  } else {
    formData.append('email', email)
    formData.append('password', password)
    formData.append('type', type)
  }
  return await axiosInstance.post(`login/`, formData, { handlerEnabled, 
    headers: {    
      'Content-Type': 'multipart/form-data',
    }
  }).then(res => res.data);
}

const forgetPassword = async (email) => {
  const formData = new FormData();
  formData.append('email', email)
  return await axiosInstance.post(`forget-password/`, formData, { handlerEnabled,
     headers: {    
      'Content-Type': 'multipart/form-data',
    }
  }).then(res => res.data);
}

const register = async(form) => {
  return await axiosInstance.post(`signup/`, form, { handlerEnabled,
    headers: {    
      'Content-Type': 'multipart/form-data'
    }
  }).then(res => res.data);
}

const updateProfile = async(form) => {
  return await axiosInstance.post(`updateProfile/`, form, { handlerEnabled,
    headers: {    
      'Content-Type': 'multipart/form-data',
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
}

const updatePassword = async(password, newpassword, type, email="") => {
  const formData = new FormData();
  formData.append('password', password) // type:'reset' then it regard as token
  formData.append('newpassword', newpassword)
  formData.append('type', type)
  formData.append('email', email)
  return await axiosInstance.post( type=="reset" ? `resetPassword/` : `updatePassword/`, formData, { handlerEnabled,
    headers: {    
      'Content-Type': 'multipart/form-data',
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
}

const getUserProfile = async () => {
  return await axiosInstance.get(`userprofile/`, { handlerEnabled,
    headers:{
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
};

const getUserProgress = async (type, begin, end, testId = 0) => {
  return await axiosInstance.get(`getUserProgress/?type=${type}&begin=${begin}&end=${end}&testId=${testId}`, { handlerEnabled,
    headers: {
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
}
export default {
  login,
  forgetPassword,
  register,
  updateProfile,
  updatePassword,
  getUserProfile,
  getUserProgress
};
