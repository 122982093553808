import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import FlatButton from '../FlatButton'
import { indigo, green, lightGreen } from '@material-ui/core/colors';
import DoneIcon from '@material-ui/icons/Done';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Switch from '@material-ui/core/Switch';
import { COLORS } from "../../utils/Constants";
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({    
    button: {
        color: 'black',
        fontWeight: 600,
        fontSize: 16,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: '#E4E5EB',
        borderRadius: 7,
        margin: 5,
        textTransform: 'none',
        padding: '10px 15px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    titleIcon: {
      display: 'flex',
      alignItems: 'center'
    },
    icon: {
        color: COLORS.whiteButtonText,
    },
    info: {
      color: "#c5c8d8",
      fontSize: 16,
      marginLeft: 8
    }
}));

const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 20,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(20px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: COLORS.whiteButtonText,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#eee',
        border: '1px solid #fff',
      },
    },
    thumb: {
      width: 19,
      height: 19,
    },
    track: {
      borderRadius: 20 / 2,
      border: `1px solid ${COLORS.borderColor}`,
      backgroundColor: 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
});

export default function SwitchButton({ title, disable=false, onChange }) {
    const classes = useStyles();
    const [state, setState] = React.useState({
      checkedA: true,
      checkedB: false,
      checkedC: true,
    });

    const handleChange = (event) => {
      setState({ ...state, [event.target.name]: event.target.checked });
      onChange(event.target.checked)
    };
    return (
        <Button
            variant="outlined"
            className={classes.button}
            disabled={disable}
            endIcon={<IOSSwitch disabled={disable} checked={state.checkedB} onChange={handleChange} name="checkedB" />}
        >
            <div className={classes.titleIcon}>
              {title}
              <InfoIcon className={classes.info}/>
            </div>
        </Button>
    );
}