import React, { useEffect } from "react";
import messages from "./../../assets/Local/messages";
import { connect, useSelector, useDispatch } from "react-redux";
import { setCurrentLang } from "../../store/actions/Lang";
import { Link } from "react-router-dom";
import { Btn } from "../Controls/Button/Button";
import { makeStyles } from '@material-ui/core/styles';
import SchoolIcon from '@material-ui/icons/School';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ButtonBase from '@material-ui/core/ButtonBase';
import ProfileMenu from '../ProfileMenu';
import { useHistory } from "react-router-dom";
import { getImageAbsURL } from '../../utils/common';
import { COLORS } from "../../utils/Constants";
import useWindowDimensions from './useWindowDimensions';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    borderBottom: 'solid 1px #e4e5eb',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0px'
  },
  avatar: {
    width: 35,
    height: 35,
    border: '1px solid #eaebef'
  },
  button: {
    color: '#c5c8d8',
    fontWeight: 700
  },
  activebutton: {
    color: COLORS.whiteButtonText,
    fontWeight: 700
  },
  popover: {
    backgroundColor: 'transparent'
  },
  switchWrapper: {
    '&>button': {
      marginRight: 80
    }
  },
  imagewrapper: {
    width: 35,
    height: 35,
    border: '1px solid #DEDFE5',
    borderRadius: "50%",
    overflow: "hidden",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  pictureImage: {
    width: 23,
    height: 23,
    objectFit: 'convertColorToString',
  },
  avatarImage: {
    width: 35,
    height: 35,
    objectFit: 'convertColorToString',
  },
}));

function Navbar({profile, isGuest}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [offset, setOffset] = React.useState(0);
  const {width, height} = useWindowDimensions();
  
  function getOffset( el ) {
      var _x = 0;
      var _y = 0;
      while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
          _x += el.offsetLeft - el.scrollLeft;
          _y += el.offsetTop - el.scrollTop;
          el = el.offsetParent;
      }
      return { top: _y, left: _x };
  }
  const setAnchorOffset = () => {
    const element = anchorRef.current;
    var bodyRect = document.body.getBoundingClientRect();
    var x = getOffset(element).left; 
    setOffset(bodyRect.width - x - 40);
  }
  useEffect(() => {
    setAnchorOffset();
  }, [width])
  const handleClick = (event) => {
    setOpen(true);
    setAnchorOffset();
  };

  const handleClose = (event) => {
    setOpen(false);
  };


  const switchLanguage = lang => {
    dispatch(setCurrentLang(lang === "ar" ? "en" : "ar"));
  };
  const learnActive = history.location.pathname == '/learn';
  const practiceActive = history.location.pathname == '/practice';
  const avatar = profile ? getImageAbsURL(profile.avatar) : '';
  return (
    <>
      <nav className={classes.root}>
        <div className={classes.container}>
          <div className={classes.switchWrapper}>            
            <Button
              className={learnActive ? classes.activebutton: classes.button}
              onClick={() => {history.push('/learn')}}
            >
              <SchoolIcon style={{fontSize: 30, marginRight: 10}} /> Learn
            </Button>      
            <Button
              className={practiceActive ? classes.activebutton: classes.button}
              onClick={() => {history.push('/practice')}}
            >
              <EditIcon style={{fontSize: 30, marginRight: 10}} /> PRACTICE
            </Button>
          </div>
          <div>
            <ButtonBase
              focusRipple
              className={classes.button}
              focusVisibleClassName={classes.focusVisible}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <div className={classes.imagewrapper}>
                <img src={avatar ? avatar : require("../../assets/images/defaultAvatar.png")}
                    alt="profile-img"
                    className={/learner/ig.exec(avatar) ? classes.pictureImage : classes.avatarImage}
                />
              </div>
            </ButtonBase>
              {open && <ClickAwayListener onClickAway={handleClose}>
              <div style={{
                position: 'absolute',
                zIndex: 100,
                top: 50,
                right: offset,
                width: 400
              }}>
              <ProfileMenu isGuest={isGuest} profile={profile} handleClose={handleClose}/>
              </div>
              </ClickAwayListener>
              }
          </div>
        </div>
      </nav>
    </>
  );
}

const mapStateToProps = ({ mathbot }) => ({
  profile: mathbot.user,
  isGuest: mathbot.isGuest
});

const mapActions = () => {
return {
}
}
export default connect(mapStateToProps, mapActions)(Navbar);