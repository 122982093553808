const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);
export const BASE_URL = isLocalhost ? 'http://10.10.10.129:8000' : `https://${window.location.hostname}/api`;
export const titleMap = {
  'G': 'Geometry',
  'OA': 'Algebra',
  'NBT': 'Base10',
  'MD': 'Measurements',
  'NF': 'Fractions',
}
export const titles = [
    {G: 'Geometry'},
    {OA: 'Algebra'},
    {NBT: 'Base10'},
    {MD: 'Measurements'},
    {NF: 'Fractions'},
  ];
  
export const tempTopicList = [{G: 0}, {OA: 0}, {NBT: 0}, {MD: 0}, {NF: 0}];
export const tempGradeList = [{3: 0}, {4: 0}, {5: 0}, {6: 0}, {7: 0}];

export const COLORS = {
  primary: '#4E54B5',
  second: '#FFCF56',
  pink: '#FF38B0',
  avatarColor: '#1976d2',
  avatarShadowBackground: '#f0f2f9',
  buttonTextPrimary: 'white',
  background: '#F2F2F2',
  textBackground: '#F3F3F3',
  lightGreen: '#33DEAB',

// button colors
  orangeButtonText: '#684A00',
  orangeButtonBackground: '#FFCF56',
  blueButtonText: '#FFFFFF',
  blueButtonBackground: '#4D5BC4',
  redButtonText: '#FFFFFF',
  redButtonBackground: '#D35151',
  whiteButtonText: '#566AFF',
  whiteButtonBorder: '#E4E5EB',
  whiteButtonBackground: '#fff',
  borderButtonText: '#fff',
  borderButtonBackground: 'transparent',
};
export const GUESTTOKEN = 'guest.token';

export const TEST_MAX_TIME = 1800;
export const TEST_TOTAL_COUNT = 20;
