import React from "react";
import "./Loader.scss";
import animationData from '../../assets/animations/Bot1/data.json'
import Lottie from 'react-lottie';

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <div className="spinnerContainer d-flex justify-content-center align-items-center h-100">
      <div className="spinner-border text-primary" role="status">
        <div className="sr-only">
          <Lottie options={defaultOptions}
              isClickToPauseDisabled={true}
              height={300}
              width={300}/>
        </div>
      </div>
    </div>
  );
};

export default Loader;
