import { axiosInstance } from "./index";
import Auth from "../../utils/Auth";
const handlerEnabled = false;

// Replace endpoint and change api name
const getBotList = async () => {
  return await axiosInstance.get(`getBotList/`, { handlerEnabled}).then(res => res.data);
};

const getScoreList = async () => {
  return await axiosInstance.get(`getUserScoreList/`, { handlerEnabled,
    headers:{
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
}

const getLessonAndScoreDetail = async (id, type = 1) => { // lesson: 1, test: 2
  if (Auth.isGuest() == true) {
    if (type == 1) {
      return await getBotList().then(res => {
        const resp = res.response;
        const item = resp.find(item => item.Id == id)
        if (!item)
          return null
        return {
          data: 
          {
            Code: item.Code,
            Title: item.Title,
          }
        }
      })
    }
    else if (type == 2) {
      return await getTestList().then(res => {
        const resp = res.response;
        const item = resp.find(item => item.Id == id)
        if (!item)
          return null
        return {
          data: 
          {
            Code: item.Code,
            Title: item.Title,
          }
        }
      })
    }
  }
  return await axiosInstance.get(`getLessonAndUserScore/?id=${id}&type=${type}`, { handlerEnabled,
    headers:{
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
}

const getBotDetailFromId = async (id) => {
  return await axiosInstance.get(`getBotDetailFromId/?id=${id}`, { handlerEnabled }).then(res => res.data);
}


const getTestList = async () => {
  return await axiosInstance.get(`getTestList/`, { handlerEnabled }).then(res => res.data);
};

const getTestDetail = async (id) => {
  return await axiosInstance.get(`getTestDetailFromId/?id=${id}`, { handlerEnabled }).then(res => res.data);
};


const setUserScore = async(type, code, title, score, progress, time) => {  
  const formData = new FormData();
  formData.append('type',type)
  formData.append('code',code)
  formData.append('title',title)
  formData.append('score',score)
  formData.append('progress',progress)
  formData.append('time',time)
  return await axiosInstance.post(`setUserScore/`, formData, { handlerEnabled, headers: {    
    'Content-Type': 'multipart/form-data',
    "Authorization": `Bearer ${Auth.isAuth()}`
  } }).then(res => res.data);
}

const setRating = async( code, email, username, rating, feedback) => {  
  const formData = new FormData();
  formData.append('code', code)
  formData.append('email', email)
  formData.append('name', username)
  formData.append('rate', rating)
  formData.append('feedback', feedback)
  return await axiosInstance.post(`setLessonRating/`, formData, { handlerEnabled,
    headers: {    
      'Content-Type': 'multipart/form-data',    
      "Authorization": `Bearer ${Auth.isAuth()}`
    }
  }).then(res => res.data);
}

export default {
  getBotList,
  getScoreList,
  getBotDetailFromId,
  getLessonAndScoreDetail,
  setUserScore,
  setRating,
  getTestList,
  getTestDetail
};
